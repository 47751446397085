import axios from 'axios'
export function requestApi(url,res){
    return new Promise((resolve,inject) =>{
        let isParams = {
            device_no:uuid().get(),
            app_id:'adobe'
        }
        let params = { ...isParams, ...res }
        axios.post(url,params).then(res =>{
          resolve(res)
        }).catch(err =>{
          inject(err)
        })
      })
}
function uuid(){
    return {
        create: function() {
            let s = [];
            let hexDigits = '0123456789abcdef';
            for (let i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = '4';
            // @ts-ignore
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
            s[8] = s[13] = s[18] = s[23] = '';
            return s.join('');
        },
        get: function() {
            try {
                if (!window.localStorage.getItem('_g_uuid')) {
                    const _uuid = this.create();
                    window.localStorage.setItem('_g_uuid', _uuid);
                    return _uuid;
                } else {
                    return window.localStorage.getItem('_g_uuid');
                }
            } catch (e) {
                return false;
            }

        },
    };
}
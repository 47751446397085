import axios from 'axios'
export default {
    install(Vue) {
        Vue.mixin({
            data(){
                return {
                  list:[],
                  topPic:'',
                  companyName:'',
                  icpName:'',
                  companyArr:[
                    {host:'localhost:8080',name:'安徽正幕信息科技有限公司'},
                    {host:'auto.yx8668.com',name:'安徽正幕信息科技有限公司无锡分公司'},
                    {host:'auto.wxsyswyy.cn',name:'无锡玺晟元生物医药有限公司'},
                    {host:'auto.xnxxxk.cn',name:'无锡市曦轩信息咨询有限责任公司'},
                    {host:'ps.xnxxxk.cn',name:'无锡市曦轩信息咨询有限责任公司'},
                    {host:'auto.sgm9.cn',name:'重庆时光漫电子商务有限公司潍坊第二分公司'},
                    {host:'auto.xdh31.cn',name:'温州辉涛网络科技有限公司'},
                    {host:'auto.wxqzlkj.cn',name:'无锡起之乐科技有限公司'},
                    {host:'auto.yzhkwl.cn',name:'扬州航鲲信息科技有限公司'},
                  ],

                  yxCompany: [
                  ],


                  downloadNoJump:[

                  ]
                }
            },
            mounted() {
              let midArr = [...this.yxCompany,...this.companyArr]
              this.companyName = midArr.find(item =>item.host == window.location.host).name || ''
              this.icpName = midArr.find(item =>item.host == window.location.host).icp || ''
            },
            methods:{

              async downloadUrl(title,isall = 0) {
                const cid = sessionStorage.getItem('pdf_id') !== 'null' ? Number(sessionStorage.getItem('pdf_id')) : 0
          
                const aid = sessionStorage.getItem('tg_id') !== 'null' ? sessionStorage.getItem('tg_id') : '0'
                let requestUrl = `${process.env.VUE_APP_BASE_API_DOMAIN}/api/v1/office/get_download_url`
                this.track(1,'',title,isall)
                let isName = isall ? 'adobe':this.$route.name
                axios.post(requestUrl,{app_id: isName,cid: cid,aid: aid}).then(res =>{
                  if ( res.data.code == 200 ) {

                    const isUrl = `${window.location.origin}${res.data.data.url}`

                    this.track(1,isUrl,title,isall)
                    var a = document.createElement('a');
                    a.style.display = 'none'
                    a.href = isUrl
                    document.body.appendChild(a)
                    a.target = '_blank'
                    a.download = this.getUrlName(res.data.data.url)
                    a.click() //执行下载
                    window.URL.revokeObjectURL(a.href)
                    document.body.removeChild(a)
  
                  } else {
                    // setTimeout(() => {
                    //   this.downloadUrl(title,isall)
                    // }, 2000);
                  }
                })
              },

                click_btn(category,label = '',action = 'click') {
                try {
                  console.log(category,label = '',action = 'click');
                    _hmt.push(['_trackEvent',category,action,label])
                } catch (error) {
                  console.log(error);
                }
                },
                getUrlName(title){
                  let titleArr = title.split('/')
                  return titleArr[titleArr.length - 1]
                },

                track(isNum = 0,url = '',title = '',isall = 0){
                  let currentUrl = url == '' ? window.location.href : url
                  let eventArr = ['PAGE_LOAD','BTN_CLICK_DOWNLOAD']
                  const gameId = isall ? 'adobe':this.$route.name
                  console.log(gameId,'route');
                  let params = {
                    "device_no": this.uuid().get(),
                    "app_id": gameId, // Warcraft RedAlert
                    "data": {
                      "host": window.location.host,
                      "url": window.location.href,
                      "os": navigator.userAgent,
                      "mac": "",
                      "guid": this.uuid().get(), //
                      "version": "1.0.0", //版本号
                      "cid": sessionStorage.getItem('pdf_id') !== 'null'? Number(sessionStorage.getItem('pdf_id')): 0, // cid渠道ID
                      "click_id": sessionStorage.getItem('tg_id') !== 'null'?sessionStorage.getItem('tg_id'): '0', // 广告ID 原tg_id
                      "tg_type":  sessionStorage.getItem('tg_type') !== 'null'? Number(sessionStorage.getItem('tg_type')): 0, // 0 默认，1，百度，2, 360
                      "tg_url_type": sessionStorage.getItem('tg_url_type') !== 'null'? Number(sessionStorage.getItem('tg_type')): 0, // 0 根据前端配置
                      "types": eventArr[isNum], // PAGE_LOAD BTN_CLICK_DOWNLOAD
                      "value": JSON.stringify({
                          "url":currentUrl,
                          "element": title
                      }),
                    }
                  }
                  const isUrl = `${process.env.VUE_APP_BASE_API_DOMAIN}/api/v1/office/track`
                  axios.post(isUrl,params).then((res) =>{
                    console.log(res);
                  }).catch((error) =>{
                    this.$message.error('请求失败');
                  })
                },
                uuid(){
                    return {
                        create: function() {
                            let s = [];
                            let hexDigits = '0123456789abcdef';
                            for (let i = 0; i < 36; i++) {
                                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                            }
                            s[14] = '4';
                            // @ts-ignore
                            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
                            s[8] = s[13] = s[18] = s[23] = '';
                            return s.join('');
                        },
                        get: function() {
                            try {
                                if (!window.localStorage.getItem('_g_uuid')) {
                                    const _uuid = this.create();
                                    window.localStorage.setItem('_g_uuid', _uuid);
                                    return _uuid;
                                } else {
                                    return window.localStorage.getItem('_g_uuid');
                                }
                            } catch (e) {
                                return false;
                            }
                
                        },
                    };
                }
            }
        })
    }
}